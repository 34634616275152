<template>
  <div class="container">
    <PublicHeader :totalName="$t('share').title" />
    <div class="share_popup">
      <div class="cord_box">
        <qrcode-vue :value="link" :size="size" level="H" class="qrcode"></qrcode-vue>
      </div>
      <div class="cord_txt">  {{ $t('share').MyShareCode }}：{{ userInfo.Id }}</div>
      <div class="cord_link">{{ link }}</div>
      <div class="cord_btn" @click="onCopy(link)">
        {{ $t('share').CopyShareCode }}
      </div>
    </div>
  </div>
</template>

<script>
import systemApi from '@/api/system'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader,
    QrcodeVue
  },
  data() {
    return {
      userInfo: {},
      link: '',
      size: 210
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const aBase = await userApi.getAccountBase()
      this.userInfo = aBase.Data
      const res = await systemApi.getClientConfig()
      this.link = res.Data.ShareUrl + '/#/register?pid=' + this.userInfo.Id
    },
    //复制方法
    onCopy(text) {
      return new Promise((resolve) => {
        if (navigator.clipboard?.writeText) {
          this.$toast(this.$t('public').copySuccess)
          return resolve(navigator.clipboard.writeText(text))
        }
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        textarea.style.position = 'absolute'
        textarea.style.clip = 'rect(0 0 0 0)'
        textarea.value = text
        textarea.select()
        document.execCommand('copy', true)
        textarea.remove()
        this.$toast(this.$t('public').copySuccess)
        return resolve(true)
      })
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 65px 15px 0 15px;
  background: #F6F6F6;

  .share_popup {
    padding-bottom: 10px;
    background: #ffffff;
    border-radius: 10px;

    .cord_box {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
    }

    .cord_txt {
      font-weight: 800;
      font-size: 17px;
      color: #333333;
      text-align: center;
    }
    .cord_link{
      margin-top: 10px;
      padding: 0 15px;
      font-size: 13px;
      color: #3485FF;
      text-align: center;
    }

    .cord_btn {
      margin: 20px 30px;
      height: 40px;
      background: #C92B2E;
      border-radius: 5px;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
